$red2: #bc3d40;

body {
  font-family: "Raleway", var(--bs-body-font-family);
  zoom: 125%;
}

.container {
  width: 1120px !important;
  max-width: 94% !important;
  padding: 0;
}

.container-lg {
  max-width: 1440px !important;
  box-sizing: border-box;
}

.subcontainer {
  width: 700px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
}

p {
  letter-spacing: .25px;
}

.text-bg-light {
  background-color:  rgba(var(--bs-light-rgb), .9) !important;
}

.home {
  background-image: url('/assets/images/front-image.jpg');
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: 100%;
  main {
    padding-bottom: 400px
  }
}

footer {
  background: rgba(255,255,255,.6);
}

hr {
  margin: 2rem 0;
  opacity: .1;
}

a {
  color: var(--bs-danger);

  &:hover {
    color: $red2;
  }
}

img {
  max-width: 100%;
}

.screenshot {
  border-radius: 15px;
  box-shadow: 0 1rem 1rem rgba(1,1,1,.1);
  margin: 1rem 0 2rem 0;
  border: 1px solid var(--bs-gray-400);
}

.card {
  transition: all .3s;
  position: relative;
  .card-img {
    border-radius: 0 !important;
    transition: all .3s;
    overflow: hidden;
    object-fit: cover;
    aspect-ratio: 1.7/1;
  }
  .card-body {
    border-bottom: 4px solid var(--bs-light);
    box-shadow: 0px 0px 13px 10px rgba(0,0,0,.06);
    padding-bottom: .75rem;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  p {
    margin: 0;
  }
  .labels {
    height: 0;
    overflow: hidden;
    transition: all .3s;
  }
  &:hover,
  &:focus {
    img {
      filter: none;
    }
    .labels {
      height: auto;
      margin-top: .5rem;
    }
  }
}

.subcontainer .card-img {
  display: none;
}

.cv {
  font-size: 16px;

  .list-group-item {
    background-color:  rgba(var(--bs-light-rgb), .9) !important;
  }

  .close-btn {
    top: .25rem;
    right: (1.5rem * .5)
  }

  .employment .row:last-child {
    .job-content {
      padding-bottom: 80px !important;
    }
  }

  .job-content {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 4px;
      left: -6.5px;
      width: 12px;
      height: 12px;
      background: var(--bs-danger);;
      border-radius: 50%;
    }
  }
  
  .conf {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 8px;
      left: -5.5px;
      width: 10px;
      height: 10px;
      border: 1px solid var(--bs-danger);
      background:  rgba(var(--bs-light-rgb), .9) !important;
      border-radius: 50%;
    }
  }

  .badge {
    padding: 4px 6px;
  }
  
  .right-col {
    background: rgba(var(--bs-light-rgb), .9) !important;
  }

}

@media screen and (max-width: 960px) {
  body {
    zoom: 110%;
  }
  .cv {
    font-size: 15px;
  }
}

@media screen and (max-width: 768px) {
  body {
    zoom: 100%;
  }

  .home {
    background-size: 200%;
    background-position: bottom left;
    main {
      padding-bottom: 100px
    }
  }

  .btn {
    width: 100% !important;
  }

  .social {
    padding-top: 1rem;
  }

  .home-social .social {
    padding-top: 0;
  }

  .screenshot {
    border-radius: 5px;
    box-shadow: 0 .5rem .5rem rgba(1,1,1,.1);
    margin-bottom: 1rem;
  }

  .cv {
    font-size: 14px;
  }
}

/* Cookies */
 .cookiealert {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  border-radius: 0;
}

.cookiealert.show {
  visibility: visible;
}

@media print {
  body {
    zoom: 100%;
  }
  .cv {
    font-size: 11px;
    .close-btn,
    .alert {
      display: none;
    }
    .pt-5 {
      padding-top: 14px !important;
    }
    .pt-sm-5 {
      padding-top: 14px !important;
    }
    .col-sm-7 {
      width: 65% !important;
    }
    .col-sm-5 {
      width: 35% !important;
    }
    h1 {
      font-size: 21px !important;
    }
    .fs-4 {
      font-size: 17px !important;
    }
    .fs-5 {
      font-size: 15px !important;
    }
    .fs-6 {
      font-size: 12px !important;
    }
    .job-date {
      font-size: 9px !important;
    }
    .job-desc {
      width: 25% !important;
    }
    .job-content {
      width: 75% !important;
      &.pb-1 {
        padding-bottom: 0 !important;
      }
      &:after {
        top: 1px;
      }
    }
    .conf {
      &:after {
        top: 2px;
      }
    }
    .employment .row:last-child {
      .job-content {
        padding-bottom: 0px !important;
      }
    }
  }
}

.badge {
  color: #0c2d4a;
  background-color: #bbd4ea;
  font-weight: 600;
  min-width: 78px;
  &-grey {
    color: #282d30;
    background-color: #e5e6e7;
  }
  &-green {
    color: #005a30;
    background-color: #cce2d8;
  }
  &-red {
    color: #2a0b06;
    background-color: #f4cdc6;
  }
  &-yellow {
    color: #594d00;
    background-color: #fff7bf;
  }
  &-turquoise {
    color: #10403c;
    background-color: #d4ecea;
  }
  &-pink {
    color: #6b1c40;
    background-color: #f9e1ec;
  }
}